<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1">
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-title>Module</v-list-item-title>
              <v-list-item-subtitle>{{ module }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Sub-Module</v-list-item-title>
              <v-list-item-subtitle>{{ submodule }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>ID</v-list-item-title>
              <v-list-item-subtitle>{{ id }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-btn to="/test/other">Bad Link</v-btn>
            <v-btn to="/test/page/79">Good Link</v-btn>
            <v-btn to="/test/page/94">Good Link 2</v-btn>
            <v-btn to="/test/page/7">Good Link 3</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'
export default {
  setup (props, { root }) {
    const module = computed(() => root.$route.params.module)
    const submodule = computed(() => root.$route.params.submodule)
    const id = computed(() => root.$route.params.id)

    const componentName = ref('')
    const component = computed(() => {
      if (componentName.value !== '' && componentName.value != null) {
        return import('./Dynamic/' + componentName.value + '.vue')
      }
      return null
    })

    const pages = {
      test: {
        _component: 'Page',
        page: {
          _component: 'Page',
          id: {
            _component: 'Page',
            _regex: '[0-9]{1,3}'
          }
        }
      }
    }

    watch([module, submodule, id], () => {
      if (module.value in pages) {
        let main = pages[module.value]
        if (submodule.value == null) {
          componentName.value = main._component
          return
        }
        if (submodule.value in main) {
          if (id.value != null) {
            if ('id' in main[submodule.value]) {
              if ('_regex' in main[submodule.value].id) {
                let regex = new RegExp(main[submodule.value].id._regex)
                if (regex.test(id.value)) {
                  componentName.value = main[submodule.value].id._component
                  return
                } else {
                  console.log('Failed regex test: ' + id.value, regex)
                }
              }
            }
          } else {
            componentName.value = main[submodule.value]._component
            return
          }
        }
      }
      root.$store.dispatch('main/snackbar', { active: true, text: 'Page does not exist', color: 'error' })
      root.$router.replace('/')
    })

    return {
      module,
      submodule,
      id,
      componentName,
      component
    }
  }
}
</script>
